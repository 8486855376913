import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { SubTitle } from "./SubTitle";
import { ArticlePhotoRowLinks } from "./ArticlePhotoRowLinks";
import { ButtonLink } from './ButtonLink';
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  h2 {
    margin: 0 0!important;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0.5rem 0rem;
  margin: 2rem 0;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.sm}px) {
    display: block;
    padding: 0.5rem 0;
  }
`


export function News () {

  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            templates {
              posts {
                pathPrefix
              }
            }
          }
        }
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/posts/" } }
          sort: { order: DESC, fields: [fileAbsolutePath] }
          limit: 6
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <SubTitle en={`News`}>Latest</SubTitle>
      <ArticlePhotoRowLinks
        edges={edges}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
        isAdSense={false}
      />
      <ButtonWrapper>
        <ButtonLink to={`/posts/page/1`}>
          View More
        </ButtonLink>
      </ButtonWrapper>
    </Wrapper>
  );
}
