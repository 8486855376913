import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { PostImageLink } from "./PostImageLink";
import Marquee from "react-fast-marquee";
import { shuffle } from "../utils/Shuffle";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
`

const MarqueeWrapper = styled.div`
  margin: .15rem 0;
`

export const MarqueeCategoryArticles = ({ category, speed=20 }) => {

  const { allMdx: { edges } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            templates {
              posts {
                pathPrefix
                filters {
                  category {
                    pathPrefixCategory
                  }
                }
              }
            }
          }
        }
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/posts/" } }
          sort: { order: DESC, fields: [fileAbsolutePath] }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const articles = shuffle(edges.filter(edge => edge.node.frontmatter.category === category));

  if (articles.length === 0) {
    return(<></>);
  }

  return (
    <Wrapper>
      <MarqueeWrapper>
        <Marquee speed={speed} gradient={false} pauseOnHover={false}>
          {
            articles.map(article => {
              return (
                <PostImageLink id={article.node.frontmatter.id} width={200} mx={"2rem"} />
              )
            })
          }
        </Marquee>
      </MarqueeWrapper>
    </Wrapper>
  );
}
