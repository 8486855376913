import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { breakpoints } from "../style/theme";

const Wrapper = styled(Link)`
  display: inline-block;
  width: 100%;
  margin: ${props => props.my || 0} ${props => props.mx || 0};
  //@media screen and (max-width: ${breakpoints.sm}px) {
  //  display: none;
  //}
`

const Article = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`

const ArticleImageWrapper = styled.div`
  width: ${props => props.width ? props.width + "px" : "100%"};
  max-width: 400px;
  margin: 0;
  //@media screen and (max-width: ${breakpoints.sm}px) {
  //  width: 100%;
  //  max-width: none;
  //  padding: 0;
  //}
`

const ImgWrapper = styled.p`
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  .gatsby-image-wrapper {
    border-radius: 0.15rem;
  }
  picture {
    img {
      border-radius: 0.15rem;
      transition: 1s!important;
      margin: 0;
    }
  }
  &:hover {
    picture {
      img {
        transform: scale(1.2);
      }
    }
  }
`

export function PostImageLink ({id, width=null, mx=null, my=null}) {

  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              frontmatter {
                id
                title
                category
                description
                createdDate
                updatedDate
                tags
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 1280) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const posts = edges.filter(edge => edge.node.frontmatter.id === id);

  if (posts.length === 0) {
    return(<></>);
  }

  const post = posts[0].node.frontmatter;

  return (
    <Wrapper to={`/posts/${post.id}`} mx={mx} my={my}>
      <Article>
        <ArticleImageWrapper width={width}>
          <ImgWrapper>
            <Img
              fluid={post.featuredImage.childImageSharp.fluid}
            />
          </ImgWrapper>
        </ArticleImageWrapper>
      </Article>
    </Wrapper>
  );
}
