import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { SubTitle } from "./SubTitle";
import { ArticlePhotoRowLinks } from "./ArticlePhotoRowLinks";
import { MarqueeCategoryArticles } from "../components/MarqueeCategoryArticles"
import { ButtonLink } from './ButtonLink';
import theme, { breakpoints, categoryEn } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  h2 {
    margin: 2rem 0 0!important;
  }
`

export function CategoryNewArticles ({ category, limit = 3, isAdSense = false }) {

  const { site: { siteMetadata }, allMdx: { edges } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            templates {
              posts {
                pathPrefix
                filters {
                  category {
                    pathPrefixCategory
                  }
                }
              }
            }
          }
        }
        allMdx(
          filter: { fileAbsolutePath: { regex: "/content/posts/" } }
          sort: { order: DESC, fields: [fileAbsolutePath] }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const articles = edges.filter(edge => edge.node.frontmatter.category === category).slice(0, limit);

  if (articles.length === 0) {
    return(<></>);
  }

  return (
    <Wrapper>
      <SubTitle en={categoryEn[category]}>{category}</SubTitle>
      <ArticlePhotoRowLinks
        edges={articles}
        category={category}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
        isAdSense={isAdSense}
      />
      {
      //<MarqueeCategoryArticles category={category} />
      }
      <ButtonLink
        to={`/${siteMetadata.templates.posts.filters.category.pathPrefixCategory}/${category}/page/1`}
      >
        View More
      </ButtonLink>
    </Wrapper>
  );
}
