import React from "react";
import styled from "styled-components";
import theme, {breakpoints} from "../style/theme";

const Title = styled.h2`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 4rem auto 2rem;
  padding: 0.5rem 3rem 0.5rem 1rem;
  font-family: "Montserrat", "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
  font-size: 1.8rem;
  font-weight: normal;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .13em;
  word-break: break-word;
  border-left: 1px solid ${theme.palette.text.primary};
  border-bottom: 1px solid ${theme.palette.text.primary};
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 1.35rem;
  }
  &::before {
    position: absolute;
    ${({backgroundText}) => backgroundText ? `
    content: attr(data-en);
    bottom: -0.5rem;
    font-size: 4.5rem;
    ` : `
    counter-increment: section;
    content: "" counter(section);
    bottom: -1.75rem;
    font-size: 6.0rem;
    `}
    left: 1.5rem;
    font-style: italic;
    letter-spacing: .065em;
    color: #f1f1f1;
    z-index: -1;
    @media screen and (max-width: ${breakpoints.sm}px) {
      ${({backgroundText}) => backgroundText ? `
      font-size: 2.5rem;
      bottom: 0.75rem;
      ` : `
      font-size: 4.5rem;
      bottom: -1.75rem;
      `}
    }
  }
  //position: relative;
  //margin: 2rem 0;
  //padding: 1.5rem 0;
  //font-size: 1.5rem;
  //font-weight: normal;
  //text-align: left;
  //color: ${theme.palette.text.primary};
  //&::before {
  //  position: absolute;
  //  right: 0;
  //  left: 0;
  //  bottom: 0;
  //  margin: auto;
  //  content: '';
  //  width: 100%;
  //  height: 1px;
  //  background-color: rgba(0, 0, 0, 0.05);
  //}
  //&::after {
  //  content: attr(data-en);
  //  position: absolute;
  //  top: -0.75rem;
  //  left: 0;
  //  color: rgba(0, 0, 0, 0.05);
  //  font-size: 3rem;
  //  font-style: italic;
  //  @media screen and (max-width: ${breakpoints.sm}px) {
  //    top: 0rem;
  //    font-size: 2rem;
  //  }
  //}
`

export const SubTitle = ({en, children}) => {
  return (
    <Title id={children.toString().replaceAll("“", "").replaceAll("”", "").replaceAll(" ", "-").replaceAll(".", "").replaceAll("#", "").replaceAll(`"`, "").replaceAll("=", "").replaceAll(":", "").toLowerCase()} backgroundText={en} data-en={en}>{children}</Title>
  )
}