import React from "react";
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby";
import { SubTitle } from "./SubTitle";
import { ArticlePhotoRowLinks } from "./ArticlePhotoRowLinks";
import theme, { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 2rem 0;
  h2 {
    margin: 2rem 0 0!important;
  }
`

export function RecommendArticles() {

  const { allMdx: {edges} } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            image
            templates {
              posts {
                pathPrefix
              }
            }
          }
        }
        allMdx(
          filter: {
            frontmatter: { id: { in: ["30", "32", "34", "28", "27", "38", "43"] } }
            fileAbsolutePath: { regex: "/content/posts/" }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 50, truncate: true)
              fileAbsolutePath
              frontmatter {
                id
                title
                category
                description
                tags
                createdDate
                updatedDate
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 720) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <SubTitle en={`For you`}>Recommend</SubTitle>
      <ArticlePhotoRowLinks
        edges={edges}
        fontColor={theme.palette.white}
        backgroundColor={theme.palette.primary.main}
        breakpoint={breakpoints.sm}
        isAdSense={false}
      />
    </Wrapper>
  );
}
