import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Main from "../components/Main";
import SideBar from "../components/SideBar";
import { News } from "../components/News"
import { RecommendArticles } from "../components/RecommendArticles"
import { PopularArticles } from "../components/PopularArticles"
import { CategoryNewArticles } from "../components/CategoryNewArticles"
import { Grid } from "@material-ui/core";
import theme, {breakpoints, categoryColors} from "../style/theme";
import { useHasScroll } from "has-scroll-hook";

const PostsContainer = styled.div`
  position: relative;
  width: 100%;
  background-color: ${theme.palette.white};
  z-index: 1;
`;

const PostsWrapper = styled.div`
  width: 100%;
  max-width: ${breakpoints.lg}px;
  margin: 0 auto;
`;

export default function HomeTemplate() {
  /* Get the vertical scrollbar offset as a boolean value. */
  const hasScroll = useHasScroll();

  return (
    <Layout
      title={`ITベンチャーエンジニアの日常`}
      firstView={true}
      elevateAppBar={hasScroll}>
      <News />
      <RecommendArticles />
      <PopularArticles />
      {
        Object.keys(categoryColors).map(key => {
          return (<CategoryNewArticles category={key} isAdSense={true} />)
        })
      }
    </Layout>
  );
}
